// ナビゲーションメニューの表示/非表示
$(function() {
  $('.c-header__nav_toggle').click(function() {
    $('nav.c-header__nav').toggleClass('d-none d-md-inline');
    if ($(this).attr('data-status') == 'off') {
      $('.c-header__nav_toggle > svg.svg-inline--fa').removeClass('fa-bars');
      $('.c-header__nav_toggle > svg.svg-inline--fa').addClass('fa-times');
      $(this).removeAttr('data-status');
      $(this).attr('data-status', 'on');
    } else {
      $('.c-header__nav_toggle > svg.svg-inline--fa').removeClass('fa-times');
      $('.c-header__nav_toggle > svg.svg-inline--fa').addClass('fa-bars');
      $(this).removeAttr('data-status');
      $(this).attr('data-status', 'off');
    }
  });
});
