// お問い合わせフォームボタンの有効/無効
$(function() {
  $('#agree_1').change(function() {
    if ($(this).prop('checked')) {
      $('#contact_button').removeClass('disabled');
    } else {
      $('#contact_button').addClass('disabled');
    }
  });
});
