// ページの先頭へ戻るボタン
$(function() {
  $(window).scroll(function() {
    // 最上部から現在位置までの距離を取得して、変数[now]に格納
    var now = $(window).scrollTop();
    // 最上部から現在位置までの距離(now)が300以上
    if (now > 300) {
      // [#page-top]をゆっくりフェードインする
      $('#page_top').show();
      // それ以外だったらフェードアウトする
    } else {
      $('#page_top').hide();
    }
  });
  // ボタン(id:move-page-top)のクリックイベント
  $('#page_top').on('click', function() {
    // ページトップへ移動する
    $(window).scrollTop(0);
  });
});
