// 下スクロールでヘッダー一部非表示し上スクロールで表示
$(function() {
  var startPos = 0;
  $(window).on('load scroll resize', function() {
    var winScrollTop = $(this).scrollTop();
    if (winScrollTop > startPos && winScrollTop > 500) {
      // 非表示
      $('.c-header__logo_tagline').removeClass('d-md-block');
      $('.c-header__nav').removeClass('d-md-inline');
      $('.c-header').addClass('c-header--slim');
    } else {
      // 表示（IE11のみ表示しない）
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('trident/7') == -1 || winScrollTop < 500) {
        $('.c-header__logo_tagline').addClass('d-md-block');
        $('.c-header__nav').addClass('d-md-inline');
        $('.c-header').removeClass('c-header--slim');
      }
    }
    startPos = winScrollTop;

    // body要素のpadding-topを変更
    var header_size = $('.c-header').outerHeight(true);
    $('body').css('padding-top', header_size + 'px');
  });

  // マウスクリックまたはタッチで不可視要素を表示
  $('header').on('click', function() {
    $('.c-header__logo_tagline').addClass('d-md-block');
    $('.c-header__nav').addClass('d-md-inline');
    $('.c-header').removeClass('c-header--slim');

    // body要素のpadding-topを変更
    var header_size = $('.c-header').outerHeight(true);
    $('body').css('padding-top', header_size + 'px');
  });

  // マウスカーソルのホバーで不可視要素を表示
  $('header').hover(function() {
    $('.c-header__logo_tagline').addClass('d-md-block');
    $('.c-header__nav').addClass('d-md-inline');
    $('.c-header').removeClass('c-header--slim');

    // body要素のpadding-topを変更
    var header_size = $('.c-header').outerHeight(true);
    $('body').css('padding-top', header_size + 'px');
  });
});
